table td, table td {
    white-space: nowrap;
    font-size: 13px;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 5px !important;
}

.ant-form-item .ant-form-item-label >label {
    font-size: 12px !important;
}
.ant-typography.ant-typography-edit-content {
    height: 31px !important;
}
.center-label .ant-form-item-label {
    display: flex !important;
    justify-content: center !important;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #f0f0f0;
}
.ant-picker-datetime-panel {
    flex-direction: column !important;
    align-items: center !important;
}
.ant-picker-dropdown .ant-picker-datetime-panel .ant-picker-time-panel {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-segmented-item-selected {
    background-color: #3e79f7 !important; 
    color: #ffffff !important;
}
  
.ant-segmented-thumb {
    background-color: #3e79f7 !important;
}
.ant-segmented .ant-segmented-item {
    width: 100% !important;
}

.parlay-betSlip-border li {
    border: 1px solid #57575794;
    border-top: none;
}
.parlay-betSlip-border li:first-child {
    border-top: 1px solid #57575794 !important;
}

.roleType .ant-radio-button-wrapper {
    padding-inline: 0px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.created-odds .ant-select-selector {
    background-color: #d18700 !important;
    border: 1px solid #d18700 !important;
    border-right-color: #e6ebf1 !important;
    color: #ffffff !important;
}

.created-odds .ant-select-arrow {
    color: #ffffff !important;
}

@media (max-width: 560px) {
    .ant-picker-panels {
        display: block !important;
        width: 310px !important;
    }
}
.report-date::-webkit-calendar-picker-indicator {
    display: none; /* Safari & Chrome calendar icon hide */
}
.report-date {
    border: 1px solid #b7b7b7;
    border-radius: 3px;
    font-size: 14px;
    color: #616161;
    padding: 1px 1px 1px 5px;
    outline: none;
    user-select: none;
    width: 111px;
    height: 30px !important;
}
.report-date-select {
    border: 1px solid #b7b7b7;
    border-radius: 3px;
    font-size: 14px;
    color: #616161;
    padding: 4px;
    outline: none;
    user-select: none;
    width: 111px;
    height: 30px !important;
}

.report-search {
    border: 1px solid #b7b7b7;
    border-radius: 3px;
    font-size: 12px;
    color: #616161;
    padding: 5px;
    outline: none;
    user-select: none;
    width: 120px;
}

.submitBtn {
    background-color: #3e79f7;
    color: #ffffff;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 120px;
}

.excelDownloadBtn {
    background-color: #198754;
    color: #ffffff;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 120px;
}

.backBtn {
    background-color: #1a3353;
    color: #ffffff;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    cursor: pointer;
}

@keyframes colorChange {
    0% { color: red; }
    25% { color: blue; }
    50% { color: green; }
    75% { color: orange; }
    100% { color: red; }
  }
  
.color-animation {
    animation: colorChange 3s infinite;
}


.custom-date-time-dropdown .ant-picker-datetime-panel {
    display: flex !important;
    flex-direction: row !important;
    gap: 10px; /* Date နဲ့ Time ကြားအကွာ */
}

.custom-date-time-dropdown .ant-picker-datetime-panel {
    width: auto !important;
}